import { useContext } from 'react';

import { Link } from 'react-router-dom';

/* CONTEXT */
import { FirebaseDataContext } from '../../../firebase';

/* routes */
import { ADMIN, ADMINISTRATIVE_REVIEW } from '../../../routing/routes';

/* LAYOUT */
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';

import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

const useStyles = makeStyles((theme) => ({
  listHeader: {
    flexGrow: 1,
    '&:not(:first-child)': {
      marginTop: 30,
    },
    color: theme.palette.primary.main,
    fontWeight: 700,
  },
  separator: {
    height: 1,
    width: '80%',
    marginLeft: '10%',
    marginTop: 15,
    backgroundColor: '#ccc',
  },
  aapNameHeader: {
    fontWeight: 700,
    marginBottom: 10,
  },
  listContainer: {
    padding: '10px 20px',
  },
  projectLink: {
    margin: '5px 0',
    border: '1px solid #ccc',
    padding: '5px 10px',
    borderRadius: 5,
    display: 'block',
    color: '#333',
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    // transition:"background-color 150ms linear",

    '&.accepted': {
      color: 'white',
      backgroundColor: theme.palette.secondary.main,
    },
    '&.rejected': {
      color: 'white',
      backgroundColor: theme.palette.error.main,
    },
    '&.pending': {
      color: 'white',
      backgroundColor: theme.palette.info.main,
    },
    '&.warning': {
      color: 'white',
      backgroundColor: theme.palette.warning.main,
    },
    '&.waiting': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    '&.rlink.pending': {
      color: 'white',
      backgroundColor: theme.palette.primary.main,
    },
    '&.rlink.onReview': {
      color: 'white',
      backgroundColor: '#eede47',
    },

    '&:hover': {
      // color:'white',
      color: '#333!important',
      backgroundColor: '#eee!important',
    },
    '&.pendingReEntry': {
      // color:'white',
      color: '#333!important',
      backgroundColor: '#eee!important',
    },
  },
  linkContainer: {
    display: 'inline-block',
    verticalAlign: 'top',
    width: '21%',
    marginRight: '3%',
    [theme.breakpoints.down('sm')]: {
      width: '90%',
      marginRight: '5%',
    },
  },
}));

export const AdminList = (props) => {
  const firebaseDataContext = useContext(FirebaseDataContext);

  const classes = useStyles();

  const dataLoaded = firebaseDataContext.definitionsRead && firebaseDataContext.projectsRead && firebaseDataContext.evaluation_flagRead && firebaseDataContext.administrativeReviewsRead;

  /* Create strucutred object data  */
  const submittedAAP = {},
    pendingReEntryAAP = {},
    filteredOutAAP = {},
    nonConvertedAAP = {},
    analyzedAAP = {},
    pendingSubmissionAAP = {};

  // const TT = {};
  // Object.entries(firebaseDataContext.administrativeReviews || {}).forEach(([i, j]) => {
  //   // console.log(j);
  //   if (j.flag === 'round2') {
  //     if (j.result === 'waiting' && (j.email || '').includes('Une première analyse de votre dossier montre que les éléments suivants sont manquants')) {
  //       TT[i] = j;

  //       console.log(i);
  //       console.log(firebaseDataContext.userProjects[i]);
  //     }
  //   }
  // });
  // console.log(Object.keys(TT).length);
  // console.log(TT);

  // console.log(firebaseDataContext.administrativeReviews);
  /*
  aapIDs.forEach((aapId) => {
    submittedAAP[aapId] = [];
    analyzedAAP[aapId] = [];
    pendingReEntryAAP[aapId] = [];
    pendingSubmissionAAP[aapId] = [];
  });
  */

  /*
  aapIDs.forEach((aapId) => {
    if (firebaseDataContext[`${aapId}Read`] && firebaseDataContext[`review_${aapId}Read`]) {
      Object.entries(firebaseDataContext[aapId]).forEach((entry) => {
        const [key, value] = entry;
        const rev = firebaseDataContext[`review_${aapId}`];
        // REMOVE KEYS
        // if (key !== '7JIAFABMAHT94BR6UG2dkzZKdpT2' && key !== '8M3VoHWXceNr7pNF3DufpyoXHiG3') {
        //   return;
        // }

        if (value.submitted) {
          if (key in rev && rev[key].status === 'reviewed') analyzedAAP[aapId].push(key);
          else submittedAAP[aapId].push(key);
        } else {
          if (key in rev && rev[key].status === 'pending') {
            pendingReEntryAAP[aapId].push(key);
          } else pendingSubmissionAAP[aapId].push(key);
        }
      });
    }
  });
  */

  const numberOfEntries = (entries) => {
    return Object.keys(entries)
      .map((k) => Object.keys(entries[k]).length)
      .reduce((acc, arr) => acc + arr, 0);
  };

  const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  };

  const colonnesSuivi = [
    { title: 'Project #', group: 'status', field: 'project_number' },
    { title: 'Catégorie', group: 'status', field: 'aap_id' },
    // { title: 'Id', group:'status', field: 'project_number'},
    { title: 'Submitted', group: 'status', field: 'submitted' },
    { title: 'Catégorie anticipée', group: 'data', field: 'anticipated_category' },
    { title: 'Waiting for conversion', group: 'status', sub_groups: ['reviewed'], field: 'next_step' },
    { title: 'Acronyme', group: 'data', field: 'acronyme' },
    { title: 'Identité', group: 'data', field: 'identity' },
    { title: 'Statut', group: 'data', field: 'status' },
    { title: 'Description', group: 'data', field: 'description' },
    { title: 'Explication', group: 'data', field: 'explain' },
  ];


  const downloadSuivi = () => {
    const columns = [];
    colonnesSuivi.forEach((c) => {
      columns.push(c.title);
      // columns.push({ title: `${c}Message` });
    });

    const evaluationFlag = firebaseDataContext.evaluation_flag;
    const administrativeQuestions = (firebaseDataContext.evaluationDefinitions[evaluationFlag] || {}).administrativeQuestions || [];
    const reviewFields = [
      {
        title: 'Result',
        field: 'result',
        // map: (r) => {
        //   return r === -1 ? 'rejected' : r === 0 ? 'warning' : 'accepted';
        // },
      },
      // { title: 'Message', field: 'message' },
      // { title: 'Email contact juridique', field: 'email', inProjectData: true },
      // { title: 'Email de contact', field: 'contact_email', inProjectData: true },
      { title: 'Dernier Email Envoyé', field: 'email' },
      { title: 'Message Générél', field: 'message' },
    ];
    administrativeQuestions.forEach((reviewSec) => {
      reviewSec.questions.forEach((q) => {
        reviewFields.push({
          title: q.text,
          field: q.id,
        });
        reviewFields.push({
          title: 'Message associé',
          field: `${q.id}_message`,
        });
      });
    });

    reviewFields.forEach((c) => {
      columns.push(c.title);
    });


    const wb = XLSX.utils.book_new();
    wb.Props = {
      Title: 'Suivi des analyses administratives',
      Subject: 'Admin reviews',
      Author: 'Arnaud Browet',
      CreatedDate: new Date(),
    };

    wb.SheetNames.push('Analyses admnitratives');

    // set the header
    const ws_data = [columns];

    // filter submitted project
    // const submittedProject = Object.entries(firebaseDataContext.userProjects || {}).filter(([projectId, projectData]) => {
    //   return projectData.status.submitted;
    // });

    // const submittedProject = Object.entries(firebaseDataContext.userProjects || {}).filter(([projectId, projectData]) => {
    //   return projectData.status.project_number;
    // });

    const submittedProject = [];

    for (const dataset of [submittedAAP, pendingReEntryAAP, nonConvertedAAP, analyzedAAP, pendingSubmissionAAP]) {
      Object.entries(dataset).forEach(([aapName, aapData]) => {
        Object.entries(aapData).forEach(([uid, dd]) => {
          submittedProject.push([uid, dd]);
        });
      });
    }

    submittedProject.sort((s1, s2) => {
      return s1[1].status.project_number - s2[1].status.project_number;
    });

    submittedProject.forEach(([projectId, projectData]) => {
      const outputRow = [];

      colonnesSuivi.forEach((cs) => {
        let dataField = projectData[cs.group] || {};
        if (cs.sub_groups) {
          cs.sub_groups.forEach((sg) => {
            dataField = dataField[sg] || {};
          });
        }
        // const valueField = (projectData[cs.group] || {})[cs.field] ?? '';
        const valueField = dataField[cs.field] ?? '';
        outputRow.push(valueField);
      });

      // console.log('add review field', projectId, projectData, firebaseDataContext.administrativeReviews[projectId]);
      reviewFields.forEach((rf) => {
        const valueField = (firebaseDataContext.administrativeReviews[projectId] || {})[rf.field] ?? '';

        if (rf.field === 'result') {
          const pp = firebaseDataContext.administrativeReviews[projectId] || {};
          if (pp.flag !== firebaseDataContext.evaluation_flag || pp.status !== 'reviewed') {
            outputRow.push('');
            return;
          }
        }
        if (rf.map) {
          outputRow.push(rf.map(valueField));
        } else if (rf.inProjectData) {
          const v = (firebaseDataContext.userProjects[projectId] || { data: {} }).data[rf.field] ?? '';
          outputRow.push(v);
        } else {
          if (typeof valueField === 'boolean') {
            outputRow.push(valueField ? 'oui' : 'non');
          } else {
            outputRow.push(valueField);
          }
        }
      });

      ws_data.push(outputRow);
    });

    var ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets['Analyses admnitratives'] = ws;

    var wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });

    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), 'suivi_admin.xlsx');
  };

  if (!dataLoaded) {
    return (
      <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
        <CircularProgress thickness={6} size={50} />
      </Grid>
    );
  }

  // assign each project
  const userProjectsSorted = Object.entries(firebaseDataContext.userProjects).sort((p1, p2) => {
    const pn1 = p1[1].status.project_number || 0;
    const pn2 = p2[1].status.project_number || 0;

    if (pn1 < pn2) return -1;
    if (pn1 > pn2) return 1;
    return 0;
  });

  const evaluationFlag = firebaseDataContext.evaluation_flag;
  const currentReviewRound = firebaseDataContext.evaluationDefinitions[evaluationFlag] || {};

  for (const [projectId, projectData] of userProjectsSorted) {
    if (firebaseDataContext.removeTestProject && projectData.status.test) continue;

    const aap_id = projectData.status.aap_id || 'Uncategorized';

    // filter non converted projects
    if (projectData.status.reviewed && projectData.status.reviewed.status === 'accepted' && projectData.status.reviewed.next_step === 'select_aap') {
      if (!(aap_id in nonConvertedAAP)) nonConvertedAAP[aap_id] = {};

      nonConvertedAAP[aap_id][projectId] = projectData;
      continue;
    }

    // filter aap not in current review round
    if (currentReviewRound.evaluatedAAPID && !currentReviewRound.evaluatedAAPID.includes(projectData.status.aap_id)) {
      if (!(aap_id in filteredOutAAP)) filteredOutAAP[aap_id] = {};

      filteredOutAAP[aap_id][projectId] = projectData;
      continue;
    }

    if (!projectData.status.submitted) {
      const pendingList = projectData.status.submitted === false ? pendingReEntryAAP : pendingSubmissionAAP;

      // if not submitted, add project to pending submission
      if (!(aap_id in pendingList)) pendingList[aap_id] = {};

      pendingList[aap_id][projectId] = projectData;
      continue;
    }

    // project is submitted, add to either submitted or analyzed

    if (
      projectId in firebaseDataContext.administrativeReviews &&
      firebaseDataContext.administrativeReviews[projectId].status === 'reviewed' &&
      firebaseDataContext.administrativeReviews[projectId].result !== 'waiting' &&
      firebaseDataContext.administrativeReviews[projectId].flag === firebaseDataContext.evaluation_flag
    ) {
      if (!(aap_id in analyzedAAP)) analyzedAAP[aap_id] = {};
      analyzedAAP[aap_id][projectId] = projectData;
      continue;
    }

    if (!(aap_id in submittedAAP)) submittedAAP[aap_id] = {};
    submittedAAP[aap_id][projectId] = projectData;
  }

  // console.log(pendingReEntryAAP);

  // Object.entries(pendingReEntryAAP).forEach(([cat, projectsList]) => {
  //   console.log('Categorie', cat);
  //   Object.keys(projectsList).forEach((pId) => {
  //     const email = (firebaseDataContext.administrativeReviews[pId] || {}).email || 'Null';
  //     const acronyme = firebaseDataContext.userProjects[pId].data.acronyme;
  //     const email_juridique = firebaseDataContext.userProjects[pId].data.email || '';
  //     const email_contact = firebaseDataContext.userProjects[pId].data.contact_email || '';
  //     console.log('Acronome : ', acronyme);
  //     console.log('Projet ID :', pId);
  //     console.log('Email plateforme :');
  //     console.log('Email juridique :', email_juridique);
  //     console.log('Email contact :', email_contact);
  //     console.log('Last Email : ', email);
  //     console.log('');
  //     console.log('');
  //   });
  // });

  return (
    <Container component="div" maxWidth={false} style={{ paddingTop: 20, paddingBottom: 50 }}>
      {/* <Box display="flex">
            <Button variant="outlined" color="primary" onClick={downloadAllForms}>
              Télécharger tous les fichiers
            </Button>
          </Box> */}
      <Box display="flex">
        <Box style={{ flex: 1 }}>
          <Typography variant="h6" className={classes.listHeader}>
            Projets soumis à analyser [{numberOfEntries(submittedAAP)}]
          </Typography>
          <ToBeAnalyzed projectsData={firebaseDataContext} projects={submittedAAP} administrativeReviews={firebaseDataContext.administrativeReviews} />

          {Object.keys(pendingReEntryAAP).length > 0 && (
            <>
              <div className={classes.separator}></div>

              <Typography variant="h6" className={classes.listHeader}>
                Projets en attente de modification [{numberOfEntries(pendingReEntryAAP)}]
              </Typography>
              <Analyzed pendingReEntry={true} projectsData={firebaseDataContext} projects={pendingReEntryAAP} administrativeReviews={firebaseDataContext.administrativeReviews} />
            </>
          )}

          <div className={classes.separator}></div>

          <Typography variant="h6" className={classes.listHeader}>
            Projets analysés [{numberOfEntries(analyzedAAP)}]
          </Typography>
          <Analyzed projectsData={firebaseDataContext} projects={analyzedAAP} administrativeReviews={firebaseDataContext.administrativeReviews} />

          <div className={classes.separator}></div>

          <Typography variant="h6" className={classes.listHeader}>
            Projets en cours de soumission [{numberOfEntries(pendingSubmissionAAP)}]
          </Typography>
          <PendingList projectsData={firebaseDataContext} projects={pendingSubmissionAAP} />

          {Object.keys(nonConvertedAAP).length > 0 && (
            <>
              <div className={classes.separator}></div>
              <Typography variant="h6" className={classes.listHeader}>
                Projets accepté NON CONVERTI [{numberOfEntries(nonConvertedAAP)}]
              </Typography>
              {/* <PendingList projectsData={firebaseDataContext} projects={nonConvertedAAP} /> */}
              <Analyzed pendingReEntry={true} projectsData={firebaseDataContext} projects={nonConvertedAAP} administrativeReviews={firebaseDataContext.administrativeReviews} />
            </>
          )}

          {Object.keys(filteredOutAAP).length > 0 && (
            <>
              <div className={classes.separator}></div>
              <Typography variant="h6" className={classes.listHeader}>
                Projets non analysé pour ce round [{numberOfEntries(filteredOutAAP)}]
              </Typography>
              <PendingList projectsData={firebaseDataContext} projects={filteredOutAAP} />
            </>
          )}

          <div className={classes.separator}></div>
        </Box>

        <Box style={{ padding: '0 10px' }}>
          <Paper elevation={3} style={{ padding: 15 }}>
            <Typography component="p">Légende des couleurs</Typography>
            <div>
              <div className={`${classes.projectLink} accepted`}>projet accepté</div>
            </div>
            <div>
              <div className={`${classes.projectLink} rejected`}>projet rejeté</div>
            </div>
            {/* <div>
              <div className={`${classes.projectLink} pending`}>projet en attente</div>
            </div> */}
            <div>
              <div className={`${classes.projectLink} warning`}>projet avec attention</div>
            </div>
            <div>
              <div className={`${classes.projectLink} rlink pending`}>projet modifié</div>
            </div>
            <div>
              <div className={`${classes.projectLink} rlink onReview`}>projet en cours d'analyse</div>
            </div>
          </Paper>

          <Box style={{ marginTop: 50 }}>
            <Button variant="outlined" color="primary" onClick={downloadSuivi}>
              Télécharger le fichier de suivi
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const PendingList = (props) => {
  const classes = useStyles();

  // const categories = {
  //   'Sans catégorie': 0,
  // };

  // Object.entries(props.projects).forEach((proj) => {
  //   const [projectId, projectData] = proj;

  //   if (projectData.status && projectData.status.aap_id) {
  //     const cat = projectData.status.aap_id;
  //     categories[cat] = (categories[cat] || 0) + 1;
  //   } else {
  //     categories['Sans catégorie'] += 1;
  //   }
  // });

  return (
    <>
      {Object.entries(props.projects).map((d) => {
        const [catName, aap_projects] = d;
        const catNumber = Object.keys(aap_projects).length;
        // const [catName, catNumber] = d;

        return (
          <div key={`pending_${catName.replace(' ', '_')}`} className={classes.listContainer}>
            {catNumber > 0 && (
              <div className={classes.aapNameHeader}>
                {props.projectsData.definitions[catName].name_fr || 'Unkown'} - {catNumber} projet{catNumber > 1 ? 's' : ''}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

const ToBeAnalyzed = (props) => {
  const classes = useStyles();

  // const { url } = useRouteMatch();
  // const { url } = useMatch();
  const url = ADMIN + ADMINISTRATIVE_REVIEW;

  return (
    <>
      {Object.entries(props.projects).map((d) => {
        const [aapId, projects] = d;
        // const rev = props.projectsData[`review_${aapId}`];

        return (
          <div key={`tba_${aapId}`} className={classes.listContainer}>
            {Object.keys(projects).length > 0 && <div className={classes.aapNameHeader}>{props.projectsData.definitions[aapId].name_fr}</div>}
            {Object.entries(projects).map((proj) => {
              const [projectId, projectData] = proj;

              // const { sec1_firstname, sec1_lastname } = props.projectsData[aapId][project]['tab_1'];
              // const name = `${sec1_firstname} ${sec1_lastname}`;
              const project_number = projectData.status.project_number || 0;
              const name = projectData.data.acronyme || projectData.data.identity || '???';

              const isOnReview = projectId in props.administrativeReviews && (props.projectsData.evaluation_flag || '..') === props.administrativeReviews[projectId].flag;
              // const isPending = false; // project in rev ? rev[project].status === 'pending' : false;
              const isPending = isOnReview && props.administrativeReviews[projectId].result === 'waiting';

              return (
                <div key={`tba_${projectId}`} className={classes.linkContainer}>
                  <Link to={`${url}/${projectId}`}>
                    <div className={`${classes.projectLink} rlink ${isOnReview && !isPending ? 'onReview' : ''} ${isPending ? 'pending' : ''}`}>
                      {project_number !== 0 ? `#${project_number} - ` : ''}
                      {name}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};

const Analyzed = (props) => {
  const classes = useStyles();
  // console.error('use route match must be updated to usematch in ToBe Analyzed');
  // const { url } = useRouteMatch();
  // const { url } = useMatch();

  const url = ADMIN + ADMINISTRATIVE_REVIEW;

  const { pendingReEntry } = props;

  return (
    <>
      {Object.entries(props.projects).map((d) => {
        const [aapId, projects] = d;
        // const rev = props.projectsData[`review_${aapId}`];

        return (
          <div key={`ana_${aapId}`} className={classes.listContainer}>
            {Object.keys(projects).length > 0 && <div className={classes.aapNameHeader}>{props.projectsData.definitions[aapId].name_fr}</div>}
            {Object.entries(projects).map((proj) => {
              const [projectId, projectData] = proj;
              // const { sec1_firstname, sec1_lastname } = props.projectsData[aapId][project]['tab_1'];
              // const name = `${sec1_firstname} ${sec1_lastname}`;

              // const name = props.projectsData[aapId][project]['tab_2'].sec0_acronyme || props.projectsData[aapId][project]['tab_1'].sec0_identity;
              // const name =
              //   (props.projectsData[aapId][project]['tab_2'] && props.projectsData[aapId][project]['tab_2'].sec0_acronyme) ||
              //   (props.projectsData[aapId][project]['tab_1'] && props.projectsData[aapId][project]['tab_1'].sec0_identity) ||
              //   '???';
              const name = projectData.data.acronyme || projectData.data.identity || '???';
              const status = projectId in props.administrativeReviews ? props.administrativeReviews[projectId].status || null : null;
              const project_number = projectData.status.project_number || 0;
              const resultValue = projectId in props.administrativeReviews ? props.administrativeReviews[projectId].result ?? null : null;
              // const result = resultValue === -1 ? 'rejected' : resultValue === 0 ? 'warning' : 'accepted';
              const result = resultValue;

              return (
                <div key={`tba_${projectId}`} className={classes.linkContainer}>
                  <Link to={`${url}/${projectId}`} style={{ pointerEvents: pendingReEntry ? 'none' : '' }}>
                    <div className={`${classes.projectLink} ${pendingReEntry ? 'pendingReEntry' : ''} ${status === 'reviewed' && !pendingReEntry ? result : ''} ${status === 'pending' && status}`}>
                      {project_number !== 0 ? `#${project_number} - ` : ''}
                      {name}
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
};
