import React, { useContext, useEffect, useRef } from 'react';

import { Routes, Route, Link, useNavigate, useMatch, Navigate } from 'react-router-dom';

// import ScrollIntoView from '../scrollToTop';
import ScrollToTop from '../scrollToTop';

/* ROUTES */
import * as routes from '../../routing/routes';

/* CONTEXT */
import { FirebaseContext, FirebaseDataProvider, UsersListProvider, ReviewersListProvider } from '../../firebase';

/* LAYOUT */
import { AdminToolbar } from './toolbar';
import { AdminList } from './adminList';
import { AdminSpec } from './adminSpec';
import { ManageUsers } from './master/manageUsers';
import { ManageThemes } from './master/manageThemes';
import { ThemeList } from './themeAdmin/themeList';
import { GlobalEvaluation } from './themeAdmin/globalEvaluation';

import { ReviewerList } from './reviewerList';
import { ReviewerSpecProject } from './reviewerSpec';

import { HelpdeskList } from './helpdesk/helpdeskList';
import { HelpdeskViewTicket } from './helpdesk/helpdeskViewTicket';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';

import Filter9PlusIcon from '@mui/icons-material/Filter9Plus';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import CategoryIcon from '@mui/icons-material/Category';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

/* STYLES */
const useStyles = makeStyles((theme) => ({
  main: {
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
  },
  boxContainer: {
    flex: 1,
    overflow: 'hidden',
  },
  navMenu: {
    overflowY: 'auto',
    width: 'auto',
    maxWidth: 250,
    minWidth: 150,
    borderRightWidth: 1,
    borderRightStyle: 'solid',
    paddingRight: 10,
    borderRightColor: theme.palette.primary.main,
    '& ul': {
      listStyle: 'none',
      padding: 0,
      '& li': {
        padding: '10px 20px',
        fontWeight: 700,
        color: '#666',

        '& a': {
          textDecoration: 'none',
          color: '#666',
          '&:visited, &:focus': {
            color: 'inherit',
          },
          '&.active, &:hover': {
            color: theme.palette.primary.main,
          },
        },
      },
    },
  },

  menuIcon: {
    width: 16,
    height: 16,
    marginRight: 8,
    display: 'block',
  },

  sectionContainer: {
    flexGrow: 1,
    overflowY: 'auto',
  },

  policyButton: {
    display: 'inline-block',
    color: '#212529',
    textAlign: 'center',
    verticalAlign: 'middle',
    backgroundColor: 'transparent',
    border: '1px solid transparent',
    padding: '15px 30px 30px 30px',
    textDecoration: 'none',
    fontSize: '.8rem',
    lineHeight: 1.5,
  },
}));

export const Admin = () => {
  // console.log('hello admin');
  const firebaseState = useContext(FirebaseContext);
  // const history = useHistory();
  const navigate = useNavigate();

  // const {path, url} = {path:'', url:''}
  // const baseRouteMatch = useMatch(`${routes.ADMIN}`);
  const match = useMatch(`${routes.ADMIN}/*`);
  // console.log('basematch is', baseRouteMatch);
  // console.log('match is', match);

  // const location = useLocation();
  // console.log('location is', location)

  const classes = useStyles();

  const { admin, reviewer, master, themeAdmin, helpdesk } = firebaseState.claims;
  const { user } = firebaseState;

  useEffect(() => {
    const { admin, reviewer, master, themeAdmin, helpdesk } = firebaseState.claims;

    if (!user) {
      // history.replace(routes.LOGIN);
      navigate(routes.LOGIN, { replace: true });
    } else if (!admin && !reviewer && !master && !themeAdmin && !helpdesk) {
      firebaseState.app.doSignOut().then(() => {
        window.location.href = routes.userApp;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, firebaseState.app, firebaseState.claims]);

  let baseUrl = routes.EVALUATION;

  if (master) baseUrl = routes.MANAGE_USERS;
  else if (admin) baseUrl = routes.ADMINISTRATIVE_REVIEW;
  else if (themeAdmin) baseUrl = routes.MANAGE_REVIEWERS;
  else if (helpdesk) baseUrl = routes.HELPDESK;

  const inPath = (path, relative) => path.includes(relative);

  const containerRef = useRef(null);

  if (!user) {
    return <></>;
  }

  return (
    <Container component="main" maxWidth={false} className={classes.main} disableGutters>
      <AdminToolbar />

      <FirebaseDataProvider user={user} admin={admin} themeAdmin={themeAdmin} reviewer={reviewer} helpdesk={helpdesk}>
        <Box className={classes.boxContainer} display="flex">
          <Container component="nav" className={classes.navMenu} maxWidth={false} disableGutters>
            <ul>
              {master && (
                <>
                  <li>
                    <Link to={`${routes.ADMIN}${routes.MANAGE_USERS}`} className={`${inPath(match.pathname, routes.ADMIN + routes.MANAGE_USERS) ? 'active' : ''}`}>
                      <Grid container wrap="nowrap" direction="row" alignItems="center">
                        <Grid item>
                          <PeopleAltIcon className={classes.menuIcon} />
                        </Grid>
                        <Grid item>Utilisateurs</Grid>
                      </Grid>
                    </Link>
                  </li>

                  {/* <li>
                    <Link to={`${routes.ADMIN}${routes.MANAGE_THEMES}`} className={`${inPath(match.pathname, routes.ADMIN + routes.MANAGE_THEMES) ? 'active' : ''}`}>
                      <Grid container wrap="nowrap" direction="row" alignItems="center">
                        <Grid item>
                          <CategoryIcon className={classes.menuIcon} />
                        </Grid>
                        <Grid item>Thematiques</Grid>
                      </Grid>
                    </Link>
                  </li> */}
                </>
              )}
              {admin && (
                <li>
                  <Link to={`${routes.ADMIN}${routes.ADMINISTRATIVE_REVIEW}`} className={`${inPath(match.pathname, routes.ADMIN + routes.ADMINISTRATIVE_REVIEW) ? 'active' : ''}`}>
                    <Grid container wrap="nowrap" direction="row" alignItems="center">
                      <Grid item>
                        <AssignmentIcon className={classes.menuIcon} />
                      </Grid>
                      <Grid item>Analyse administrative</Grid>
                    </Grid>
                  </Link>
                </li>
              )}
              {themeAdmin && (
                <>
                  <li>
                    <Link to={`${routes.ADMIN}${routes.MANAGE_REVIEWERS}`} className={`${inPath(match.pathname, routes.ADMIN + routes.MANAGE_REVIEWERS) ? 'active' : ''}`}>
                      <Grid container wrap="nowrap" direction="row" alignItems="center">
                        <Grid item>
                          <AssignmentIndIcon className={classes.menuIcon} />
                        </Grid>
                        <Grid item>Reviewers</Grid>
                      </Grid>
                    </Link>
                  </li>
                  <li>
                    <Link to={`${routes.ADMIN}${routes.GLOBAL_EVALUATION}`} className={`${inPath(match.pathname, routes.ADMIN + routes.GLOBAL_EVALUATION) ? 'active' : ''}`}>
                      <Grid container wrap="nowrap" direction="row" alignItems="center">
                        <Grid item>
                          <MilitaryTechIcon className={classes.menuIcon} />
                        </Grid>
                        <Grid item>Evaluations globales</Grid>
                      </Grid>
                    </Link>
                  </li>
                </>
              )}
              {reviewer && (
                <li>
                  <Link to={`${routes.ADMIN}${routes.EVALUATION}`} className={`${inPath(match.pathname, routes.ADMIN + routes.EVALUATION) ? 'active' : ''}`}>
                    <Grid container wrap="nowrap" direction="row" alignItems="center">
                      <Grid item>
                        <Filter9PlusIcon className={classes.menuIcon} />
                      </Grid>
                      <Grid item>Evaluation</Grid>
                    </Grid>
                  </Link>
                </li>
              )}
              {helpdesk && (
                <li>
                  <Link to={`${routes.ADMIN}${routes.HELPDESK}`} className={`${inPath(match.pathname, routes.ADMIN + routes.HELPDESK) ? 'active' : ''}`}>
                    <Grid container wrap="nowrap" direction="row" alignItems="center">
                      <Grid item>
                        <ContactSupportIcon className={classes.menuIcon} />
                      </Grid>
                      <Grid item>Helpdesk</Grid>
                    </Grid>
                  </Link>
                </li>
              )}
            </ul>
          </Container>

          {/* <ScrollIntoView > */}
          <ScrollToTop element={containerRef.current}>
            <Container ref={containerRef} component="section" className={classes.sectionContainer} maxWidth={false} disableGutters>
              <Routes>
                <Route path="" element={<Navigate replace to={routes.ADMIN + baseUrl} />} />

                {/* master routes */}
                <Route
                  exact
                  path={routes.MANAGE_USERS}
                  element={
                    master ? (
                      <UsersListProvider>
                        <ManageUsers />
                      </UsersListProvider>
                    ) : (
                      <Navigate replace to={routes.ADMIN + baseUrl} />
                    )
                  }
                />

                {false &&
                  <Route
                    exact
                    path={routes.MANAGE_THEMES}
                    element={
                      master ? (
                        <UsersListProvider>
                          <ManageThemes />
                        </UsersListProvider>
                      ) : (
                        <Navigate replace to={routes.ADMIN + baseUrl} />
                      )
                    }
                  />
                }
                {/* admin routes */}
                <Route exact path={routes.ADMINISTRATIVE_REVIEW} element={admin ? <AdminList /> : <Navigate replace to={routes.ADMIN + baseUrl} />} />

                <Route exact path={`${routes.ADMINISTRATIVE_REVIEW}/:projectId`} element={admin ? <AdminSpec containerRef={containerRef} /> : <Navigate replace to={routes.ADMIN + baseUrl} />} />

                {/* themeAdmin routes */}
                <Route
                  exact
                  path={routes.MANAGE_REVIEWERS}
                  element={
                    themeAdmin ? (
                      <ReviewersListProvider>
                        <ThemeList />
                      </ReviewersListProvider>
                    ) : (
                      <Navigate replace to={routes.ADMIN + baseUrl} />
                    )
                  }
                />

                <Route
                  exact
                  path={routes.GLOBAL_EVALUATION}
                  element={
                    themeAdmin ? (
                      <ReviewersListProvider>
                        <GlobalEvaluation />
                      </ReviewersListProvider>
                    ) : (
                      <Navigate replace to={routes.ADMIN + baseUrl} />
                    )
                  }
                />
                <Route
                  exact
                  path={`${routes.GLOBAL_EVALUATION}/:projectId`}
                  element={
                    themeAdmin ? (
                      <ReviewersListProvider>
                        <ReviewerSpecProject global containerRef={containerRef} />
                      </ReviewersListProvider>
                    ) : (
                      <Navigate replace to={routes.ADMIN + baseUrl} />
                    )
                  }
                />

                {/* reviewer routes */}
                <Route exact path={routes.EVALUATION} element={reviewer ? <ReviewerList /> : <Navigate replace to={routes.ADMIN + baseUrl} />} />

                <Route exact path={`${routes.EVALUATION}/:projectId`} element={reviewer ? <ReviewerSpecProject containerRef={containerRef} /> : <Navigate replace to={routes.ADMIN + baseUrl} />} />

                {/* helpdesk routes */}
                <Route exact path={routes.HELPDESK} element={helpdesk ? <HelpdeskList /> : <Navigate replace to={routes.ADMIN + baseUrl} />} />

                <Route exact path={`${routes.HELPDESK}/:ticketId`} element={helpdesk ? <HelpdeskViewTicket></HelpdeskViewTicket> : <Navigate replace to={routes.ADMIN + baseUrl} />} />

                {/* Default rout */}
                <Route path="*" element={<Navigate replace to={routes.ADMIN + baseUrl} />} />
              </Routes>

              <a className={classes.policyButton} href="http://www.arctik.eu/" target="_blank" rel="noreferrer">
                Arctik - Communication for sustainability
              </a>
            </Container>
            {/* </ScrollIntoView> */}
          </ScrollToTop>
        </Box>
      </FirebaseDataProvider>
    </Container>
  );
};

// const CustomRoute = (props) => {
//   if (props.validRoute) {
//     return <Route {...props}> {props.children}</Route>;
//   }
//   return <Navigate to={routes.ADMIN} />;
// };
